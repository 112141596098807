// import { ReactNode } from "react";
import { FC } from "react";
import { Page as CES2024Page } from "./ces2024/Page";
import { Page as DaliVipOccupancyPage } from "./daliVipOccupancy/Page";
import { Page as MerckAubonnePage } from "./merckAubonne/Page";
import { Page as WoWShowroomPage } from "./wowShowroom/Page";
import { Page as FoireDuTronePage } from "./foireDuTrone/Page";
import { Page as VelazquezLoungePage } from "./velazquezLounge/Page";
import { Page as MarseillePage } from "./marseille/Page";

export enum CUSTOM_PAGES {
  CES2024 = "ces2024",
  DALI_VIP_OCCUPANCY = "dali-vip-occupancy",
  MERCK_AUBONNE = "merck-aubonne",
  WOW_SHOWROOM = "wow-showroom",
  FOIRE_DU_TRONE = "foiredutrone",
  VELAZQUEZ_LOUNGE = "velazquezlounge",
  MARSEILLE = "icts",
}

export const getCustomPage = (id: string): FC => {
  switch (id) {
    case CUSTOM_PAGES.CES2024:
      return CES2024Page;
    case CUSTOM_PAGES.DALI_VIP_OCCUPANCY:
      return DaliVipOccupancyPage;
    case CUSTOM_PAGES.MERCK_AUBONNE:
      return MerckAubonnePage;
    case CUSTOM_PAGES.WOW_SHOWROOM:
      return WoWShowroomPage;
    case CUSTOM_PAGES.FOIRE_DU_TRONE:
      return FoireDuTronePage;
    case CUSTOM_PAGES.VELAZQUEZ_LOUNGE:
      return VelazquezLoungePage;
    case CUSTOM_PAGES.MARSEILLE:
      return MarseillePage;
    default:
      // TODO add component for customApp not found
      return CES2024Page;
  }
};
